import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import {
  convertDateTimeToUTC,
  convertInstallWindowToReadableString,
  isEmpty,
} from '../../../common/common';
import { useDispatch } from 'react-redux';
import { saveScheduleDateTime } from '../../../store/action/OrderAction';
import { useScheduleDate } from '../../../hooks/order';
import { parseISO, format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import ScheduleInstallationCalendar from '../Calender';

const ScheduleInstallation = (props) => {
  const dispatch = useDispatch();
  const { scheduleAppt, isMobileAdded } = props;
  const { scheduleDateTime = '' } = useScheduleDate();
  const dateTime = scheduleDateTime
    ? convertInstallWindowToReadableString(scheduleDateTime)
    : '';

  const [reqInstallDate = '', reqInstallTime = ''] = dateTime
    ? dateTime.split('between')
    : [];

  const [scheduleState, setScheduleState] = useState({
    timeSlots: [],
    isSelectedTime: reqInstallTime ? reqInstallTime : '',
    calenderSelectedDate: reqInstallDate
      ? format(new Date(reqInstallDate), 'yyyy-MM-dd')
      : '',
  });
  const { timeSlots, isSelectedTime, calenderSelectedDate } = scheduleState;

  const [dateTimeSlots, setDateTimeSlots] = useState({});

  useEffect(() => {
    if (scheduleAppt && scheduleAppt.length) {
      const updatedDateTimeSlots = {};
      scheduleAppt.forEach((items) => {
        const getScheduleData = items.split('|');
        const date = format(new Date(getScheduleData[0]), 'yyyy-MM-dd', {
          timeZone: 'UTC',
        });
        const getDateTime = getScheduleData.filter(
          (splitTime) => splitTime !== '0'
        );
        const startTime = `${formatInTimeZone(
          parseISO(getDateTime[0]),
          'UTC',
          'h:mm a'
        )} to ${formatInTimeZone(parseISO(getDateTime[1]), 'UTC', 'h:mm a')}`;
        if (updatedDateTimeSlots[date]) {
          updatedDateTimeSlots[date].push(startTime);
        } else {
          updatedDateTimeSlots[date] = [startTime];
        }
      });
      setDateTimeSlots(updatedDateTimeSlots);
    }
  }, [scheduleAppt]);

  const getTimeSlots = (date) => {
    const formattedDate = format(parseISO(date), 'yyyy-MM-dd');
    return (
      dateTimeSlots &&
      dateTimeSlots[formattedDate] &&
      dateTimeSlots[formattedDate].map((item) => {
        if (item) {
          return item;
        }
        return false;
      })
    );
  };

  useEffect(() => {
    if (calenderSelectedDate === '' && Object.keys(dateTimeSlots).length) {
      const firstDate = Object.keys(dateTimeSlots)[0];
      setScheduleState({
        ...scheduleState,
        calenderSelectedDate: firstDate,
        timeSlots: getTimeSlots(firstDate),
      });
    }
  }, [calenderSelectedDate, dateTimeSlots]);

  useEffect(() => {
    if (scheduleDateTime.date) {
      setScheduleState({
        ...scheduleState,
        timeSlots: getTimeSlots(scheduleDateTime.date),
      });
    }
  }, [scheduleDateTime.date]);

  const handleSubmit = () => {
    let combineDate = `${format(
      parseISO(calenderSelectedDate), // Parse the date as ISO
      'yyyy-MM-dd'
    )} to ${isSelectedTime}`;
    const convertedDate = convertDateTimeToUTC(combineDate);
    dispatch(saveScheduleDateTime(convertedDate));
    props.setActiveKey(isMobileAdded ? '2' : '3');
  };

  const handleNoThanks = () => {
    dispatch(saveScheduleDateTime(''));
    props.setActiveKey(isMobileAdded ? '2' : '3');
  };

  const getInitialDate = (selectedMon) => {
    for (let key in dateTimeSlots) {
      let d = parseISO(key);
      let n = format(d, 'M');
      if (parseInt(n) === selectedMon) {
        return key;
      }
    }
  };

  return (
    <>
      <div className="calender-row">
        <Row>
          <Col span={12} className="main-calender responsive-width-set">
            <h3 className="text-center under-tab-heading">
              Appointment Preference
            </h3>
            <div className="site-calendar-demo-card">
              {calenderSelectedDate && Object.keys(dateTimeSlots).length && (
                <ScheduleInstallationCalendar
                  calenderSelectedDate={calenderSelectedDate}
                  getTimeSlots={getTimeSlots}
                  scheduleState={scheduleState}
                  setScheduleState={setScheduleState}
                  getInitialDate={getInitialDate}
                  dateTimeSlots={dateTimeSlots}
                />
              )}
            </div>
          </Col>
          <Col span={12} className="main-arrival-wnidow responsive-width-set">
            <h3 className="text-center under-tab-heading">
              Technician&apos;s Arrival Window
            </h3>
            {timeSlots &&
              timeSlots?.length > 0 &&
              timeSlots.map((timeVal, i) => {
                return (
                  <div className="text-center arrival-window-button" key={i}>
                    <button
                      onClick={() => {
                        setScheduleState({
                          ...scheduleState,
                          isSelectedTime: timeVal,
                        });
                      }}
                      className={
                        timeVal === isSelectedTime ? 'blue-border' : ''
                      }
                    >
                      {timeVal === isSelectedTime ? (
                        <i
                          className="fa fa-check-circle check-icon-green"
                          aria-hidden="true"
                        ></i>
                      ) : null}
                      {timeVal}
                    </button>
                  </div>
                );
              })}
            <div className="calender-below-buttons">
              <button
                className="blue-button grey-button"
                onClick={() => handleNoThanks()}
              >
                Skip
              </button>
              <button
                className="blue-button"
                onClick={() => handleSubmit()}
                disabled={isEmpty(isSelectedTime)}
              >
                Confirm
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ScheduleInstallation;
